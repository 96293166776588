.product-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 2rem 20rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .product-container {
    padding: 2rem;
    max-width: 100%;
    flex-basis: 100%;
    justify-content: center;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 20px;
}

th {
  background-color: #35A757;
}

td {
  background-color: #2F2E41;
  color: #F2F2F2;
}

/* New additions for centering title on small screens */
@media screen and (max-width: 600px) {
  .product-container {
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }

  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  table, thead, tbody, th, td, tr {
    display: block;
    width: 100%;
  }

  thead {
    display: none;
  }

  tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  tr:nth-child(odd) {
    background: #3b3a52;
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  td:before {
    content: attr(data-label);
    position: absolute;
    top: 50%;
    left: 10px;
    width: calc(50% - 20px);
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    color: #fff;
    transform: translateY(-50%);
  }
}
.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 2rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}